import { Button, Card, CardActions, CardContent, CardHeader, Typography } from "@mui/material";
import { KcContextBase, KcProps, useKcMessage } from "keycloakify";
import { memo } from "react";
import {FomAreaLayout} from "@molecule/form-area-layout/FomAreaLayout";

export const Info = memo(({ kcContext, ...props }: { kcContext: KcContextBase.Info; } & KcProps) => {
  const {
    messageHeader,
    message,
    requiredActions,
    skipLink,
    pageRedirectUri,
    actionUri,
    client
  } = kcContext;

  const { msg: kcMessage } = useKcMessage();

  return <FomAreaLayout title={messageHeader} kcContext={kcContext}>

      <Typography className="instruction">
        {message?.summary}
        {
          !!requiredActions &&
          <b>
            {
              requiredActions
                .map((requiredAction: string) => kcMessage(`requiredAction.${requiredAction}` as any))
                .join(",")
            }
          </b>
        }
      </Typography>
      {
        !skipLink && !!pageRedirectUri
          ? <Button fullWidth href={pageRedirectUri}>{(kcMessage("backToApplication"))}</Button>
          : (
            !!actionUri 
              ? <Button fullWidth href={actionUri}>{(kcMessage("proceedWithAction"))}</Button>
              : !!client.baseUrl && <Button fullWidth href={client.baseUrl}>{(kcMessage("backToApplication"))}</Button>
          )
      }
  </FomAreaLayout>
})