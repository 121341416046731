import { Button, Card, CardActions, CardContent, CardHeader } from "@mui/material"
import { KcContextBase, KcProps, useKcMessage } from "keycloakify"
import { memo } from "react"
import {FomAreaLayout} from "@molecule/form-area-layout/FomAreaLayout";

export const Error = memo(({ kcContext, ...props }: { kcContext: KcContextBase.Error; } & KcProps) => {
  const { message, client } = kcContext;
  const { msg: kcMessage } = useKcMessage();

  return <FomAreaLayout title={kcMessage("errorTitle")} kcContext={kcContext}>
      {message.summary}
      {
        !!client && !!client.baseUrl &&
        <Button fullWidth id="backToApplication" href={client.baseUrl}>
          {kcMessage("backToApplication")}
        </Button>
      }

  </FomAreaLayout>
})