import {Grid, Typography, Box, useMediaQuery, useTheme, Container, Button, Link, CircularProgress} from "@mui/material";
import {KcLanguageTag, useKcLanguageTag} from "keycloakify";

import {LayoutConstant} from './constant/layout.constant';
import {Intro} from "@layout/Intro";
import {Header} from "@layout/Header";
import {ReactNode, useEffect, useState} from "react";
import {KeycloakContext} from "@type/Keycloak";
import userProjectService from "../../shared/service/user-project.service";
import {I18nMenu} from "@layout/I18nMenu";

export const Layout = ({kcContext, children}: { kcContext: KeycloakContext, children: ReactNode }) => {
    const theme = useTheme();
    const mqLg = useMediaQuery(theme.breakpoints.down('md'));

    const AllowedLanguages = typeof Object.keys(LayoutConstant.content.title);
    const {kcLanguageTag} = useKcLanguageTag() as {
        kcLanguageTag: Extract<KcLanguageTag, typeof AllowedLanguages>,
        setKcLanguageTag: () => void
    };

    const [loading, setLoading] = useState(true)
    const [projectIntroductionTitle, setProjectIntroductionTitle] = useState<{} | null>(null);
    const [projectIntroductionDescription, setProjectIntroductionDescription] = useState<{} | null>(null);
    const [imageUrls, setImageUrls] = useState<{
        introductionBanner: string | null,
        footerRuler: string | null,
        loginLogo: string | null
    }>({
        introductionBanner: null,
        footerRuler: null,
        loginLogo: null
    });

    useEffect(() => {
        const redirectUri = (new URLSearchParams(window.location.search)).get("redirect_uri")
            || localStorage.getItem('redirect_uri');


        if (redirectUri) {
            const projectUri = new URL(redirectUri);
            userProjectService.init(
                projectUri.protocol + "//" + projectUri.host,
                window.location.protocol + "//" + window.location.host
            )
            .then(val => {
                setProjectIntroductionTitle(userProjectService.getProjectIntroductionTitle());
                setProjectIntroductionDescription(userProjectService.getProjectIntroductionDescription());
                setImageUrls(userProjectService.getImagesUrls());
                setLoading(false)
            })
            .catch(err => {
                setLoading(false)
            });
        }
        if (!redirectUri) {
            if (!!userProjectService.getProjectIntroductionTitle()) {
                setProjectIntroductionTitle(userProjectService.getProjectIntroductionTitle());
                setProjectIntroductionDescription(userProjectService.getProjectIntroductionDescription());
                setImageUrls(userProjectService.getImagesUrls());
            }
            setLoading(false);
        }
    }, []);

    const {realm} = kcContext;

    return (
        <Box
            sx={{
                margin: "auto",
                height: "100vh",
                maxWidth: "1920px"
            }}

        >
            {
                loading ?
                    <Box sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100vh",
                        width: "100vw"

                    }}>
                        <CircularProgress
                            size={100}
                            variant={"indeterminate"}></CircularProgress>
                    </Box>
                    :
                    <Box
                        display="flex"
                        flex="1"
                        sx={{
                            backgroundColor: "white",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            margin: {
                                sm: "auto",
                                xs: ""
                            },
                            height: {
                                md: "100vh",
                                xs: "100%"
                            },
                            maxWidth: "1920px"
                        }}
                    >

                        <Grid container

                              sx={{
                                  alignItems: "center",
                                  justifyContent: "center",
                                  direction: {
                                      sm: "column",
                                      md: "row"
                                  },
                                  display: "flex",
                                  height: "90vh"
                              }}>

                            {
                                !mqLg &&
                                    <Intro
                                        projectIntroductionTitle={projectIntroductionTitle}
                                        projectIntroductionDescription={projectIntroductionDescription}
                                        introductionBanner={imageUrls.introductionBanner}
                                    />
                            }

                            <Grid
                                item
                                direction="column"
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    backgroundColor: "white",
                                    height: {
                                        md: "100%"
                                    }
                                }}
                            >
                                <Grid item sx={{
                                    justifyContent: "center",
                                    display: "flex"
                                }}>

                                <Button href={LayoutConstant.header.websiteUrl}>
                                    <img className={"image-gt-md"}
                                         src={imageUrls?.loginLogo ? imageUrls?.loginLogo : LayoutConstant.header.logo}
                                         alt="Otus logo"/>
                                </Button>

                                </Grid>
                                <Grid item
                                      container
                                      md={6}
                                      direction="column"
                                      sx={{
                                          justifyContent: "center",
                                          alignItems: {
                                              sm: "center"
                                          },
                                      }}
                                >
                                    <Grid item xs="auto"
                                          sx={{
                                              minWidth: "350px",
                                              width: {
                                                  md: "450px"
                                              }
                                          }}>
                                        {children}
                                    </Grid>
                                </Grid>
                                <Grid item
                                      sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          alignItems: "center"
                                      }}
                                >
                                    <Typography variant="caption" sx={{color: 'text.secondary'}}>
                                        {
                                            Object.keys(LayoutConstant.content.title).includes(kcLanguageTag)
                                                ? LayoutConstant.content.copyright[kcLanguageTag]
                                                : LayoutConstant.content.copyright.en
                                        }
                                    </Typography>

                                    <Typography variant="caption" sx={{color: 'text.secondary'}}>
                                        Otus Solutions
                                    </Typography>

                                    <Grid item sx={{display: "flex", flexDirection: "row", gap: "10px"}}>
                                        <Link underline="none"
                                              tabIndex={4}
                                              variant="body2"
                                              href={LayoutConstant.footer.linkedinUrl}>
                                            LinkedIn
                                        </Link>

                                        <Link
                                            underline="none"
                                            tabIndex={4}
                                            variant="body2"
                                            href={LayoutConstant.footer.linkedinUrl}>
                                            Instagram
                                        </Link>
                                    </Grid>
                                </Grid>

                            </Grid>


                        </Grid>
                        <Grid item
                              sx={{
                                  height: "200px",
                                  maxWidth: {
                                      md: "1620px"
                                  },
                                  display: "flex",
                                  alignItems: "end"
                              }}
                        >
                            <img
                                className={`authorization-background ${mqLg ? 'absolute' : ''}`}
                                src={imageUrls.footerRuler ? imageUrls.footerRuler : "https://storage.googleapis.com/public-assets-storage/otus-solutions/ruler.png"}
                                alt="Otus owl meeting"
                            />
                        </Grid>
                    </Box>
            }
        </Box>
    );
};
