export const LayoutConstant = {
  header: {
    logo: 'https://storage.googleapis.com/public-assets-storage/otus-solutions/login-logo-otus.png',
    websiteUrl: 'https://otus-solutions.com.br/'
  },

  footer: {
    linkedinUrl: "https://www.linkedin.com/company/otus-solutions",
    instagramUrl: "https://www.instagram.com/otussolutions",
  },

  supportLink: "https://otus-solutions.atlassian.net/servicedesk/customer/portals",
  supportTitle: {
    'pt-BR': "Dúvidas?",
    'en': "Need help?",
    'es': "¿Necesitar ayuda?"
  },
  supportText: {
    'pt-BR': "Acesse",
    'en': "Access",
    "es": "Acceso"
  },
  content: {
    title: {
      'pt-BR': 'Soluções integradas para pesquisa em saúde',
      'en': 'Integrated solutions for health research',
      "es": "Soluciones integradas para la investigación en salud"
    },
    subtitle: {
      'pt-BR': 'Garanta a segurança, acessibilidade e integração dos dados de saúde para uma medicina baseada em evidências, ' +
          'eficaz e integrada à prática clínica e epidemiológica.',
      'en': 'Ensure the security, accessibility and integration of health data for evidence-based, effective medicine integrated into clinical and epidemiological practice.',
      "es": "Garantizar la seguridad, accesibilidad e integración de los datos de salud para una medicina basada en evidencia, eficaz e integrada en la práctica clínica y epidemiológica."
    },
    subtitle1: {
      'pt-BR': 'Conheça nossas soluções clicando',
      'en': 'Discover our data management and storage solutions by clicking'
    },
    clickHere: {
      'pt-BR': 'aqui',
      'en': 'here'
    },
    copyright: {
      'pt-BR': '© 2024 todos os direitos reservados',
      'en': '© 2024 all rights reserved'
    }
  }
}