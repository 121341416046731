import { Preload } from "shared/Models/Preload";
import {HttpRestService} from "./HttpRestService";

export class GatewayRestService extends HttpRestService<Preload>{
    
    constructor() {
        const apiUrl = process.env.REACT_APP_API_GATEWAY_URL || '<REACT_APP_API_GATEWAY_URL>';
        super(apiUrl)
    }

    async getAccountPreload(uri?: string, projectId?: string, type?: string): Promise<Preload[]> {
        return super.get("/account/preload", {
            params: {
            ...( uri ? {frontendUrl: uri} : {}),
                ...(projectId ? {projectId: projectId} :  {}),
                ...(type ? {type} :  {})
            }
        })
    }
}
