import { GatewayRestService } from "shared/resource/GatewayRestService";

class UserProjectService {
    private gatewayRestService: GatewayRestService
    
    constructor() {
        this.gatewayRestService = new GatewayRestService();
    }

    async init(redirectUri: string, currentUri: string) {
        const projectPreload = (await this.gatewayRestService.getAccountPreload(redirectUri));
        if (projectPreload && projectPreload.length) {
            const preloads = (await this.gatewayRestService.getAccountPreload(undefined, projectPreload[0].project.id, "SSO"));
            localStorage.setItem('projectId', projectPreload[0].project.id);
            localStorage.setItem('projectName', projectPreload[0].project.name);
            localStorage.setItem('imagesUrls', JSON.stringify(preloads[0].imagesUrls));
            preloads[0].i18n && localStorage.setItem('projectI18n',  JSON.stringify(preloads[0].i18n));
        }
        
    }

    getProjectIntroductionTitle() {
        return localStorage.getItem('projectI18n') ? JSON.parse(localStorage.getItem('projectI18n') || "").introductionTitle : null;
    }

    getProjectIntroductionDescription() {
        return  localStorage.getItem('projectI18n') ? JSON.parse(localStorage.getItem('projectI18n') || "").introductionDescription : null;
    }

    getImagesUrls() {
        return localStorage.getItem('imagesUrls') ? JSON.parse(localStorage.getItem('imagesUrls') || "") : null;
    }
}

export default new UserProjectService();