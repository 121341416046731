import { Button, Menu, MenuItem, useMediaQuery, useTheme } from "@mui/material"
import TranslateIcon from '@mui/icons-material/Translate';
import { useState } from "react";
import { getKcLanguageTagLabel, KcLanguageTag, useKcLanguageTag } from "keycloakify";
import { KeycloakContext } from "@type/Keycloak";

export const I18nMenu = ({ kcContext }: { kcContext: KeycloakContext }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const { locale } = kcContext;
  const { kcLanguageTag, setKcLanguageTag } = useKcLanguageTag();

  const theme = useTheme();
  const mqMd = useMediaQuery(theme.breakpoints.up('md'));
  
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const setLanguage = (tag: KcLanguageTag) => {
    setKcLanguageTag(tag);
    setAnchorEl(null);
  };

  return <>
    <Button
      id="basic-button"
      aria-controls="basic-menu"
      aria-haspopup="true"
      aria-expanded={open ? 'true' : undefined}
      onClick={handleClick}
      variant="outlined"
      color="primary"
      size="small"
    >
      {   getKcLanguageTagLabel(kcLanguageTag) }
    </Button>

    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      {
        locale?.supported
        .map(supportedLocale => {
          return <MenuItem key={supportedLocale.languageTag} onClick={() => setLanguage(supportedLocale.languageTag)}>
            {getKcLanguageTagLabel(supportedLocale.languageTag)}
          </MenuItem>
        })
      }
    </Menu>
  </>
}
