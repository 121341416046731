import {memo, useEffect, useState} from "react";
import { useCssAndCx } from "tss-react";
import {KcContextBase, KcProps, Template, useKcLanguageTag, useKcMessage} from "keycloakify";
import {Button, Card, CardActions, CardContent, CardHeader, Grid, Typography} from "@mui/material";
import {TextInput} from "@molecule/form/TextInput";
import {ActionButton} from "@atom/ActionButton";
import SendIcon from "@mui/icons-material/Send";
import {PasswordInput} from "@molecule/form/PasswordInput";
import {FomAreaLayout} from "@molecule/form-area-layout/FomAreaLayout";

export const LoginUpdatePassword = memo(({ kcContext, ...props }: { kcContext: KcContextBase.LoginUpdatePassword } & KcProps) => {
    const { cx } = useCssAndCx();

    const { kcLanguageTag } = useKcLanguageTag();

    const { msg, msgStr } = useKcMessage();

    const { url, messagesPerField, isAppInitiatedAction, username } = kcContext;

    let [passwordErrors, setPasswordErrors] = useState("")
    let [passwordConfirmErrors, setPasswordConfirmErrors] = useState("")

    useEffect(() => {
        if(messagesPerField.existsError('password')) {
            setPasswordErrors(msg('invalidPasswordMessage').props.children)
        }
        if(messagesPerField.existsError('password-confirm')) {
            setPasswordConfirmErrors(msg('invalidPasswordConfirmMessage').props.children)
        }
    }, [messagesPerField, kcLanguageTag, msg])

    return (
        <form id="kc-passwd-update-form" className={cx(props.kcFormClass)} action={url.loginAction} method="post">
            <FomAreaLayout title={msg("updatePasswordTitle")} kcContext={kcContext}>
                <input
                    type="text"
                    id="username"
                    name="username"
                    value={username}
                    readOnly={true}
                    autoComplete="username"
                    style={{display: "none"}}
                />
                <input type="password" id="password" name="password" autoComplete="current-password"
                       style={{display: "none"}}/>
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <Typography variant="body2">
                            {/*{kcMessage("emailInstruction")}*/}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <PasswordInput
                            label={msg("passwordNew")}
                            fullWidth
                            required
                            tabIndex={2}
                            name="password-new"
                            autoComplete="new-password"
                            id="password-new"
                            autoFocus
                            error={!!passwordErrors}
                            helperText={passwordErrors}
                        />
                    </Grid>
                    <Grid item>
                        <PasswordInput
                            id="password-confirm"
                            label={msg("passwordConfirm")}
                            fullWidth
                            required
                            tabIndex={2}
                            name="password-confirm"
                            autoComplete="new-password"
                            error={!!passwordConfirmErrors}
                            helperText={passwordConfirmErrors}
                        />
                    </Grid>
                </Grid>

                <Grid container px={1}>
                    <Grid item container xs={6} alignItems="center">
                        <Button href={url.loginUrl}>
                            {msg("backToLogin")}
                        </Button>
                    </Grid>

                    <Grid item container xs={6} justifyContent="end">
                        <ActionButton type="submit" name={"login"} id={"kc-login"}
                                      disabled={passwordErrors || passwordConfirmErrors} endIcon={<SendIcon/>}>
                            {msg("doSubmit")}
                        </ActionButton>
                    </Grid>
                </Grid>
            </FomAreaLayout>
        </form>
    );
});
