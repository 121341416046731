import { LayoutConstant } from "./constant/layout.constant";
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import AuthBackgroundImage from "@asset/otus-background.png";
import { KcLanguageTag, useKcLanguageTag } from "keycloakify";

export const Intro = (props: any) => {
  const theme = useTheme();
  const mqLg = useMediaQuery(theme.breakpoints.down('md'));

 const {
     projectIntroductionTitle,
     projectIntroductionDescription,
     introductionBanner
 } = props;

  const { kcLanguageTag } = useKcLanguageTag() as { kcLanguageTag: Extract<KcLanguageTag, "pt-BR" | "en">, setKcLanguageTag: () => void };
  return <Grid
      item
      container
      md={6}
      sx={{
        display: "flex",
        flexFlow: "column no-wrap",
        padding: "10px",
        height: {
          md: "100%"
            }
      }}
  >
    <Grid
        item
        container
        direction="column"
        sx={{
            display: "flex",
            gap: "10px",
            paddingTop: {
                md: "80px"
            }
        }}
    >
      <Grid item>
        <Typography color="black" textAlign="start" sx={{
            fontSize: "1.8em"
        }}>
           {
            !projectIntroductionTitle ? (Object.keys(LayoutConstant.content.title).includes(kcLanguageTag)
              ? LayoutConstant.content.title[kcLanguageTag]
              : LayoutConstant.content.title.en) :
                Object.keys(projectIntroductionTitle).includes(kcLanguageTag) ?
                    projectIntroductionTitle[kcLanguageTag]
                    : projectIntroductionTitle["pt-BR"]
          }
        </Typography>
      </Grid>
      <Grid item sx={{height: "100px"}}>
        <Typography  textAlign={"justify"} sx={{
            color: "",
            fontSize: {
                md: "1.3em",
                sm: "1em"
            }
        }}>

            {
                !projectIntroductionDescription ? (Object.keys(LayoutConstant.content.subtitle).includes(kcLanguageTag)
                        ? LayoutConstant.content.subtitle[kcLanguageTag]
                        : LayoutConstant.content.subtitle.en) :
                    Object.keys(projectIntroductionDescription).includes(kcLanguageTag) ?
                        projectIntroductionDescription[kcLanguageTag]
                        : projectIntroductionDescription["pt-BR"]
            }
        </Typography>
      </Grid>
    </Grid>
    <Grid item
      sx={{
        display: {
            md: "flex",
            xs: "none"
        },
        maxWidth: "950px",
        maxHeight: "400px",
          justifyContent: "center",

      }}
    >
      <img
        src={introductionBanner ? introductionBanner : 'https://storage.googleapis.com/public-assets-storage/otus-solutions/introduction-banner-otus.png'}
        style={{maxWidth: "100%"}}
        alt="Otus owl meeting"
      />
    </Grid>
      {/*"https://storage.googleapis.com/public-assets-storage/nudec/Frame_1.png"*/}
  </Grid>;
}