import {Box, Checkbox, FormControlLabel, Grid, Typography} from "@mui/material"
import {I18nMenu} from "@layout/I18nMenu";
import {KeycloakContext} from "@type/Keycloak";
import {ReactNode} from "react";

export const FomAreaLayout = ({kcContext, children, ...props}: { kcContext: KeycloakContext, children: ReactNode } & {title: any}) => {
    const { realm } = kcContext;

    return (
      <Box
          sx={{
              flex: "1",
              flexDirection: "column",
              gap: "20px",
              display: "flex",
          }}
      >
          <Grid container sx={{display: "flex", justifyContent: "space-between", alignItems: "center"}}>
              <Typography
                  variant="h5"
              >

                  {props.title}
              </Typography>
              <Grid item>
                  { realm.internationalizationEnabled && <I18nMenu kcContext={kcContext} /> }
              </Grid>
          </Grid>

          {children}
    </Box>
  )
}